.header {
  &-top {
    background-color: $theme-grey;
    &-menu {
      border-bottom: $thin $white;
      @include display(flex);
      @include justify-content(center);
      @include breakpoint(l) {
        border: none;
        @include justify-content(left); }
      li {
        list-style: none;
        position: relative;
        z-index: 100;
        a {
          color: $text-medium;
          display: block;
          white-space: nowrap;
          padding: rem(12) rem(14);
          border: none;
          &:hover {
            color: $theme-dark;
            text-decoration: none; }
          @include breakpoint(m) {
            border-right: $thin $theme-grey-medium;
            border-left: $thin $white; }
          @include breakpoint(l) {
            padding: rem(15) rem(14); }
          @include breakpoint(xl) {
            padding: rem(15) rem(18); } }
        &:first-child {
          a {
            border-left: none; } }
        &:last-child {
          a {
            border-right: none; } }
        &.active {
          a {
            color: $theme-dark;
            text-decoration: none; } } } } }
  &-search {
    text-align: center;
    &-wrapper {
      position: relative;
      display: inline-block;
      width: 100%;
      padding: rem(8) 0;
      @include breakpoint(m) {
        width: 60%; }
      @include breakpoint(l) {
        width: 100%; }
      input[type="search"] {
        padding-right: 30px;
        width: calc(100% - 40px); } }
    &-submit {
      border: none;
      background: none;
      position: absolute;
      right: 5px;
      top: 13px;
      opacity: .8;
      outline: none;
      &:hover {
        opacity: 1; }
      &:focus {
        opacity: 1; } } }
  &-middle {
    padding: rem(20) 0 rem(15);
    &-left {
      @include display(flex);
      @include align-items(center);
      @include justify-content(center);
      @include breakpoint(s) {
        @include justify-content(space-between); } }
    &-logo {
      display: inline-block;
      @include breakpoint(s) {
        display: block; } }
    &-adress {
      padding: 0 0 0 rem(10);
      display: none;
      @include breakpoint(m) {
        display: block; }
      @include breakpoint(l) {
        padding: 0 rem(50) 0 0; }
      p:last-child {
        margin: 0; } }
    &-right {
      padding-left: rem(20);
      text-align: center;
      @include breakpoint(s) {
        text-align: left; } }
    &-phone {
      margin: 0 10px 5px 0;
      padding-left: 35px;
      display: inline-block;
      background: url('../img/icons/phone.png') left center no-repeat;
      a {
        font-size: $text-18;
        white-space: nowrap;
        color: $text-medium;
        text-decoration: none;
        &:hover {
          cursor: pointer; }
        @include breakpoint(m) {
          &:hover {
            cursor: default; } }
        @include breakpoint(l) {
          font-size: $text-24; } } }
    &-right {
      padding: 10px 0 0 20px; }
    &-mail {
      margin-bottom: 15px;
      display: inline-block;
      padding-left: 35px;
      background: url('../img/icons/mail.png') left center no-repeat;
      a {
        color: $theme-dark-hover; } }
    &-call {
      display: inline-block;
      margin-left: 0;
      padding: 5px 10px 3px;
      color: $them-bright;
      text-decoration: none;
      border: 1px dashed $border;
      white-space: nowrap;
      border-radius: 5px;
      background-color: transparent;
      @include breakpoint(l) {
        margin-left: 35px; }
      &:hover,
      &:active {
        background-color: $theme-grey;
        color: $eco;
        border-color: transparent; }
      &:visited {
        color: $them-bright; } } } }
