html {
  font-size: $basis-font-size; }
body {
  font-size: $default-font-size;
  line-height: $default-line-height; }

h1 {
  font-size: $text-14;
  text-transform: uppercase;
  font-weight: 600; }
h2 {
  font-size: $text-14;
  font-weight: 400;
  text-transform: uppercase; }
h3 {
  font-size: $text-14;
  font-weight: 400;
  text-transform: uppercase; }
h4 {
  font-size: $text-14;
  font-weight: 600; }
h5 {
  font-size: $text-12;
  font-weight: 600; }
h6 {
  font-size: $text-12; }

h1,h2,h3,h4,h5,h6 {
  color: $theme-dark;
  font-family: inherit;
  font-weight: 400; }

h1,h2,h3 {
  margin: rem(10) 0 rem(15);
  @include breakpoint(m) {
    margin: rem(15) 0 rem(20); } }

h4,h5,h6 {
  margin: rem(10) 0 rem(20); }

p {
  font-size: $text-14;
  color: $text-dark;
  margin: 0 0 rem(25); }

a {
  color: $theme-dark;
  &:hover,
  &:focus,
  &:active {
    color: $theme-dark-hover;
    outline: none;
    text-decoration: none; } }

ul {
  margin: 0;
  padding: 0;
  li {
    font-size: $text-14; } }
