.breadcrumbs {
  width: 100%;
  padding: rem(10) 0;
  a,
  span {
    font-size: $text-12; }
  a {
    color: $breadcrumbs;
    position: relative;
    text-decoration: none;
    &:after {
      content: '/';
      display: inline-block;
      margin: 0 4px 0 6px; } }
  span {
    color: $text-light; } }
