.search_title {
  text-transform: uppercase;
  margin-bottom: 10px; }
.search_extract {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: $thin $grey-bg; }
.pagination {
  li {
    list-style: none;
    display: inline-block;
    vertical-align: top;
    margin: 0 5px;
    a {
      display: block;
      padding: 5px; } } }
