.footer {
  background-color: $theme-grey;
  margin-top: rem(40);
  padding: rem(50) 0 rem(30);
  .promo-form {
    padding-top: 0;
    padding-bottom: 0; }
  &-col {
    margin-bottom: rem(15); }
  &-list {
    text-align: center;
    @include breakpoint(m) {
      text-align: left; }
    li {
      list-style: none;
      padding-left: 20px;
      margin-bottom: rem(20);
      a {
        color: $text-dark;
        font-size: $text-16;
        position: relative;
        @include breakpoint(m) {
          &:before {
            content: '';
            position: absolute;
            top: 6px;
            left: -15px;
            width: 7px;
            height: 7px;
            background-color: $them-bright;
            border-radius: 50%; } } }
      &.active {
        a {
          text-decoration: none;
          color: $theme-dark; } } } }
  &-info {
    text-align: center;
    &-title {
      font-weight: 600;
      font-size: $text-18;
      margin-bottom: rem(30); }
    &-phone {
      margin-bottom: rem(30);
      span {
        font-size: $text-18;
        display: block;
        color: $text-medium; }
      a {
        font-size: $text-24;
        color: $them-bright;
        text-decoration: none; } }
    &-mail {
      margin-bottom: rem(30);
      span {
        font-size: $text-18;
        display: block;
        color: $text-medium;
        margin-bottom: 3px; }
      a {
        color: $theme-dark-hover;
        font-size: $text-18; } }
    &-text {
      padding: 0;
      font-size: $text-18;
      color: $text-medium;
      @include breakpoint(m) {
        padding: 0 rem(30); } } } }


