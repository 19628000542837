.carousel-photo {
  width: 100%;
  border: $thin $border;
  margin-bottom: 25px;
  @include display(flex);
  @include breakpoint(m) {
    margin-bottom: 0; }
  &-wrapper {
    position: relative;
    .slick-arrow {
      position: absolute;
      bottom: 30px;
      width: 30px;
      height: 30px;
      background-color: $theme-grey;
      border-radius: 50%;
      right: 40%;
      text-align: center;
      padding-top: 8px;
      z-index: 9;
      @include transition-duration(.3s);
      @include breakpoint(s) {
        right: 27%; }
      &:hover {
        background-color: $border;
        cursor: pointer; }
      &.slick-prev {
        right: 55%;
        @include breakpoint(s) {
          right: 33%; }
        img {
          display: inline-block;
          @include transform(rotate(180deg)); } } } }
  &-img {
    width: 40%;
    display: none;
    @include breakpoint(s) {
      display: block; } }
  &-text {
    width: 100%;
    padding: rem(35) rem(20) rem(70);
    @include breakpoint(s) {
      width: 60%; }
    &-title {
      font-size: $text-16;
      font-weight: 600;
      margin-bottom: rem(25); }
    &-item {
      font-size: $text-13;
      margin-bottom: 10px; }
    &-link {
      color: $them-bright;
      font-size: $text-16;
      &:hover {
        text-decoration: none; } } } }
