.promo-form {
  padding: rem(30) rem(20);
  @include breakpoint(l) {
    padding: rem(30) rem(50); }
  height: 100%;
  background-color: $theme-grey;
  &-title {
    font-size: $text-18;
    color: $theme-dark;
    text-transform: uppercase;
    margin-bottom: 20px;
    font-weight: 600;
    @include breakpoint(l) {
      font-size: $text-22; } }
  &-row {
    margin-bottom: rem(20);
    position: relative;
    input {
      width: 100%; }
    &.required {
      &:before {
        content: '*';
        position: absolute;
        left: -10px;
        top: 12px;
        color: $them-bright; } } } }
