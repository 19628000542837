.info-list {
  padding-left: rem(10); }
.info-item {
  background-color: $theme-grey;
  display: block;
  text-decoration: none;
  height: auto;
  margin: rem(15) 0;
  padding: rem(20);
  @include breakpoint(m) {
    height: 100%; }
  &-title {
    text-transform: uppercase;
    text-align: center;
    color: $theme-dark;
    font-weight: 600;
    padding: 0 20%;
    margin-bottom: rem(15); }
  &-img {
    margin-bottom: rem(15);
    img {
      width: 100%;
      height: auto;
      display: block;
      box-shadow: 1px 1px 4px 1px rgba($black, .4); } }
  &-text {
    padding: 0 rem(10);
    margin-bottom: rem(15); }
  &-link {
    text-align: center;
    span {
      text-transform: uppercase;
      color: $them-bright;
      text-decoration: underline; } } }

.info-list {
  .info-item {
    margin-bottom: rem(30);
    @include display(flex);
    &-wrapper {
      padding: 0 rem(25); }
    &-title,
    &-text {
      text-align: left;
      padding: 0; }
    &-img {
      min-width: rem(190);
      margin-bottom: 0;
      display: none;
      @include breakpoint(s) {
        display: block; } }
    &-link {
      text-align: right;
      a {
        text-transform: uppercase;
        color: $them-bright;
        &:hover {
          color: $theme-dark; } } } } }
