.sidebar {
  &-widget {
    margin-bottom: rem(30);
    width: 100%; }
  &-title {
    overflow: hidden;
    background-color: $grey-bg;
    padding: rem(10) rem(10) rem(8);
    text-align: center;
    &-item {
      font-size: $text-13;
      text-transform: uppercase;
      color: $theme-dark;
      margin: 0;
      font-weight: 600; } }
  &-list {
    li {
      list-style: none;
      padding: rem(15) rem(25);
      border: $thin $grey-bg;
      border-bottom: none;
      &:last-child {
        border-bottom: $thin $grey-bg; }
      a {
        position: relative;
        text-decoration: none;
        color: $text-dark;
        &:hover {
          text-decoration: underline;
          color: $theme-dark; }
        &:before {
          content: '-';
          position: absolute;
          top: 0;
          left: -10px; } }
      &.active {
        a {
          color: $text-light;
          text-decoration: none !important; } } } } }
