// Media queries breakpoints
// --------------------------------------------------

// Extra small screen / phone
$screen-xs: 480px;
$screen-phone: $screen-xs;

// Small screen / tablet
$screen-sm: 768px;
$screen-tablet: $screen-sm;

// Medium screen / desktop
$screen-md: 992px;
$screen-desktop: $screen-md;

// Large screen / wide desktop
$screen-lg: 1200px;
$screen-lg-desktop: $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);

//
// Grid system
// --------------------------------------------------

// Number of columns in the grid system
$grid-columns: 12;
// Padding, to be divided by two and applied to the left and right of all columns
$grid-gutter-width: 30px;
// Point at which the navbar stops collapsing
$grid-float-breakpoint: $screen-tablet;

// Container sizes
// --------------------------------------------------

// Small screen / tablet
$container-tablet: ((720px + $grid-gutter-width));

// Medium screen / desktop
$container-desktop: ((940px + $grid-gutter-width));

// Large screen / wide desktop
$container-lg-desktop: ((1140px + $grid-gutter-width));