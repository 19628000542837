.content {
  &-list {
    margin-top: rem(20); }
  &-popup {
    position: relative;
    margin-top: -10px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 25px;
      height: 25px;
      background-color: rgba($grey-bg, .7);
      z-index: 9; }
    &:after {
      content: '';
      position: absolute;
      height: 20px;
      width: 20px;
      bottom: 28px;
      left: 50%;
      z-index: 10;
      background: transparent url('../img/icons/zoom.png') no-repeat;
      @include transform(translateX(-50%)); }
    img {
      position: relative;
      margin-top: -20px;
      border: 2px solid $grey-bg;
      @include transition-duration(.3s); }
    &:hover {
      img {
        border-color: $theme-dark; } } }
  ul {
    margin-left: rem(40); }
  table {
    width: 100%;
    td {
      padding: rem(10);
      border: $thin $grey-bg; } }
  img {
    &.justifyleft {
      float: left;
      margin: 0 10px 10px 0; }
    &.justifyright {
      float: right;
      margin: 0 0 10px 10px; } } }
