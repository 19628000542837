.services-grid {
  border: $thin $grey-bg;
  padding: rem(40) rem(40) rem(15);
  @include display(flex);
  @include justify-content(space-between);
  @include flex-wrap(wrap);
  &-item {
    width: 100%;
    margin-bottom: rem(25);
    padding: rem(15);
    position: relative;
    @include breakpoint(s) {
      width: 50%; }
    @include breakpoint(m) {
      width: 33.333333%; }
    a {
      color: $text-dark;
      position: relative;
      &:before {
        content: ' - ';
        position: absolute;
        left: -10px;
        top: 1px;
        color: inherit; }
      &:hover {
        color: $theme-dark; } } } }
