.mobile-menu {
  display: none;
  width: 100%;
  color: $white !important;
  padding: rem(12);
  background-color: $theme-dark; }

.mobile-menu:hover {
  background-color: $theme-dark-hover; }

@media (min-width: 768px) and (max-width: 979px) {
  .mainWrap {
    width: 768px; }
  .header-top-menu li a {
    font-size: 12px;
    padding: 8px; } }

@media (max-width: 767px) {
  .mainWrap {
    width: auto;
    padding: 50px 20px; }
  .header-top-menu {
    display: none; }
  .mobile-menu {
    display: block;
    margin: 15px 0 5px; }
  .header-top-menu li {
    display: block;
    margin: 0; } }
