* {
  box-sizing: border-box; }
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0; }
body {
  min-width: $min-window-width;
  font-family: $font-main; }

a,
button[type="submit"],
input[type="submit"] {
  @include transition-duration(.3s); }

.clearfix {
  @include clearfix; }

.no-float {
  float: none !important;
  display: inline-block;
  vertical-align: top;
  margin-right: -4px; }

.center-absolute {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.align-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  @include transform(translateY(-50%)translateX(-50%)); }

.flex-row {
  @include display(flex);
  @include align-items(stretch);
  @include flex-wrap(wrap); }

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.text-left {
  text-align: left; }

.bright {
  color: $them-bright; }

.grey-bg {
  background-color: transparent;
  @include breakpoint(m) {
    background-color: $theme-grey; } }

section {
  padding: rem(20) 0; }

.titles {
  color: $theme-dark;
  text-transform: uppercase; }

.page-title {
  font-size: $text-14;
  text-transform: uppercase;
  color: $theme-dark;
  margin-left: rem(10);
  font-weight: 600; }

.block-title {
  text-align: center;
  &-item {
    padding: rem(10) rem(30) rem(8);
    background-color: $grey-bg;
    display: inline-block;
    margin: 0;
    text-transform: uppercase;
    font-size: $text-18;
    @include breakpoint(m) {
      padding: rem(10) rem(70) rem(8); }
    @include breakpoint(l) {
      padding: rem(10) rem(150) rem(8); } } }

.col-wrapper {
  border-top: 3px solid $grey-bg; }

.hide-block {
  display: none !important; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

@media (max-width: 992px) {
  .hidden-sm {
    display: none !important; } }

@media (max-width: 768px) {
  .hidden-xs {
    display: none !important; } }

@media (max-width: 480px) {
  .break-480 {
    float: none !important;
    margin-bottom: 15px;
    width: 100% !important; } }

