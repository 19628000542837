///General
$theme-dark: #043d64;
$theme-dark-hover: #065890;
$them-bright: #a10615;
$theme-grey: #ebebeb;
$theme-grey-medium: #e0e0e0;
$eco: #067017;
$eco-hover: #35834f;

//default
$white: #fff;
$black: #000;
$grey-bg: $theme-grey;

//font
$text-dark: #343434;
$text-medium: #474646;
$text-light: #9ea3a6;
$breadcrumbs: #626d75;

//form
$border: #c2c2c2;
$border-call: #959595;
$placeholder: #c2c2c2;
