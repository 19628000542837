.practics {
  border: $thin $grey-bg;
  padding: rem(20);
  @include breakpoint(m) {
    padding: rem(20) rem(65); }
  &-item {
    width: 100%;
    @include display(flex);
    @include align-items(center);
    &-img {
      width: 250px;
      display: none;
      @include breakpoint(m) {
        display: block; }
      img {
        border: 3px solid $grey-bg; } }
    &-text {
      padding: rem(35) 0;
      &-wrapper {
        background-color: $grey-bg;
        padding: rem(25); }
      &-title {
        font-weight: 600;
        font-size: $text-16;
        text-decoration: none; }
      &-item {
        text-decoration: none; }
      &-link {
        color: $them-bright;
        text-decoration: underline; } }
    &-wrap-link {
      text-decoration: none; } }
  .slick-arrow {
    position: absolute;
    display: none !important;
    top: 50%;
    width: 30px;
    height: 30px;
    background-color: $theme-grey;
    border-radius: 50%;
    right: -50px;
    text-align: center;
    padding-top: 8px;
    z-index: 9;
    @include transition-duration(.3s);
    @include transform(translateY(-50%));
    @include breakpoint(m) {
      display: block !important; }
    &:hover {
      background-color: $border;
      cursor: pointer; }
    &.slick-prev {
      right: auto;
      left: -50px;
      img {
        display: inline-block;
        @include transform(rotate(180deg)); } } }
  .slick-dots {
    position: absolute;
    bottom: 5px;
    text-align: center;
    right: 50%;
    li {
      display: inline-block;
      margin: 0 3px;
      border: none;
      border-radius: 50%;
      background-color: $border;
      width: 10px;
      height: 10px;
      &.slick-active {
        background-color: $eco; }
      &:hover {
        cursor: pointer;
        background-color: $text-dark; } }
    button {
      display: none; } } }

