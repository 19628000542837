.btn {
  display: inline-block;
  line-height: 1;
  border: none;
  text-decoration: none;
  &:active {
    outline: none; }
  &-big {
    font-size: $text-16;
    padding: rem(12) rem(25) rem(9);
    text-transform: uppercase;
    border-radius: 5px; }
  &-medium {
    font-size: $text-16;
    padding: rem(9) rem(25) rem(5);
    text-transform: uppercase;
    border-radius: 5px; }
  &-eco {
    background-color: $eco;
    color: $white;
    font-weight: 400;
    letter-spacing: .5px;
    &:hover {
      background-color: $them-bright;
      box-shadow: 1px 1px 4px 1px rgba($black, .2); }
    &:focus {
      outline: none;
      box-shadow: 0 0 5px 2px rgba($eco, .5); } }
  &-bright {
    background-color: $them-bright;
    color: $white;
    font-weight: 400;
    letter-spacing: .5px;
    &:hover {
      background-color: $eco;
      box-shadow: 1px 1px 4px 1px rgba($black, .2); }
    &:focus {
      outline: none;
      box-shadow: 0 0 5px 2px rgba($them-bright, .5); } } }
