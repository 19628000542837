#{$all-text-inputs} {
  color: $text-dark;
  border: none;
  padding: 5px 10px 3px;
  max-width: 100%;
  background-color: $white;
  box-shadow: inset 0 0 6px 1px rgba(77,75,75,.25);
  border-radius: 5px;
  font-size: 12px;
  height: 30px;
  &:focus {
    outline: $thin rgba($theme-dark-hover, .4);
    -moz-outline-radius: 5px; }
  @include placeholder {
    color: $placeholder; } }

.call-form {
  background: white;
  padding: rem(30) rem(50);
  text-align: left;
  width: 300px;
  margin: 40px auto;
  position: relative;
  border: $thin $border-call;
  border-radius: 10px;
  &-title {
    color: $theme-dark;
    text-transform: uppercase;
    font-size: $text-18;
    margin-bottom: rem(20); }
  &-row {
    margin-bottom: 10px;
    label {
      display: block;
      font-size: $text-12;
      margin-bottom: 1px;
      span {
        color: $them-bright;
        width: 10px;
        display: inline-block;
        text-align: right; } }
    textarea {
      min-height: 40px; } }
  &-input {
    box-shadow: none !important;
    border: $thin $border !important;
    width: 100%; } }



