.benefits {
  border: $thin $theme-grey;
  padding: rem(20);
  margin-bottom: 30px;
  @include display(flex);
  @include justify-content(space-between);
  @include align-items(center);
  &-img {
    img {
      display: block; } }
  &-text {
    padding: rem(10);
    color: $theme-dark;
    text-align: center;
    text-transform: uppercase;
    font-size: $text-18;
    flex: 1 0 0; } }
